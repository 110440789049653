<template>
  <NuxtLink :to="localePath(infoBox.link.href)" :class="['info-box']" :aria-label="infoBox.title">
    <div class="inner-container">
      <CloudinaryImage :public-id="publicId" :options="{ width: imgWidth, height: imgHeight }" :alt="infoBox.title" />
      <div class="flex-col content">
        <h3 v-if="infoBox.title" class="card-title">{{ infoBox.title }}</h3>
        <CustomButton v-if="infoBox.button_text" :is-small="true" :href="infoBox.link.href || '#'" style-color="white">
          {{ infoBox.button_text }}
        </CustomButton>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useDeviceStore } from '~/store/device';
import { InfoBoxData } from '~/types/contentstack';
import { getAssetPublicId } from '~/util/contentstack/csHelpers';

const deviceStore = useDeviceStore();

const props = defineProps({
  /* eslint-disable vue/prop-name-casing */
  show_image_overlay: {
    type: Boolean,
    default: true,
  },
  /* eslint-enable vue/prop-name-casing */
  infoBox: {
    type: Object as () => InfoBoxData,
    required: true,
  },
});

const localePath = useLocalePath();

const imgWidth = computed(() => (deviceStore?.isMobile ? 40 : deviceStore?.isTablet ? 160 : 160));
const imgHeight = computed(() => (deviceStore?.isMobile ? 40 : deviceStore?.isTablet ? 160 : 160));
const publicId = computed(() =>
  deviceStore?.isMobile && props.infoBox?.mobile_image?.[0]
    ? getAssetPublicId(props.infoBox.mobile_image)
    : getAssetPublicId(props.infoBox.image)
);
</script>

<style lang="scss" scoped>
.info-box {
  background: #f4f4f4;
  border-radius: 4px;
  .inner-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 1rem;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    picture {
      margin-bottom: 0.5rem;
      @include local-mixins.desktop_and_tablet {
        margin-bottom: 1.25rem;
      }
    }
    img {
      border-radius: 4px;
      width: 100%;
      height: auto;
    }
    @include local-mixins.mobile {
      align-items: center;
      justify-content: flex-start;
      padding: 0.25rem 0.5rem;
    }
    @include local-mixins.desktop_and_tablet {
      // TODO - last child padding for scroll on tablet
      position: relative;
      height: 240px;
      width: 240px;
      .content {
        z-index: 1;
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);
      }
      .cloudinary-image-container {
        height: 160px;
        width: 160px;
        img {
          border-radius: 4px;
        }
      }
    }
  }

  .card-title {
    color: $color-neutral-black;
    font-size: 1.125rem;
    text-align: center;
    + .btn {
      margin-top: 1.5rem;
    }
  }
  .card-desc {
    color: $color-neutral-black;
    font-size: 0.75rem;
  }
  img {
    transition: transform 0.2s ease;
  }

  @include local-mixins.desktop {
    &:hover {
      img {
        transform: translateY(-6px);
      }
    }
  }

  @include local-mixins.mobile {
    // width: 100%;
    // min-width: unset;
    // height: 150px;
    .card-title {
      font-size: 0.875rem;
      // margin-left: 0.25rem;
      font-family: local-vars.$ff-text;
      font-weight: 500;
    }
    img {
      // border-radius: 4px;
      // height: 100px;
      // width: 100px;
      // position: relative;
    }
    .card-desc {
      display: none;
    }
    .category-block {
      display: block;
    }
  }
}
// .category-card {
//   flex-direction: column-reverse;
//   width: 100%;
//   text-align: center;
// }
// @include local-mixins.mobile {
//   .category-card {
//     padding: 10px 0 0 0;
//   }
// }
</style>
